import React, { useState } from "react";
import {
  Alert,
  FormControl,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import db from "../firebase";
import { collection, writeBatch, doc } from "firebase/firestore";

const menues = [
  {
    value: "normal",
    label: "¡Como de todo!",
  },
  {
    value: "vegetariano",
    label: "Vegetariano",
  },
  {
    value: "vegano",
    label: "Vegano",
  },
  {
    value: "infantil",
    label: "Infantil",
  },
  {
    value: "otro",
    label: "Otro",
  },
];

const guestsNumbers = [
  {
    value: "1",
    label: "1",
  },
  {
    value: "2",
    label: "2",
  },
  {
    value: "3",
    label: "3",
  },
  {
    value: "4",
    label: "4",
  },
  {
    value: "5",
    label: "5",
  },
  {
    value: "6",
    label: "6",
  },
];

export const FormCard = () => {
  const emptyForm = [
    {
      fullName: "",
      document: "",
      menu: "normal",
      otro: "",
    },
  ];
  const [guests, setGuests] = useState(emptyForm);
  const [guestsNumber, setGuestsNumber] = useState(1);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openFailure, setOpenFailure] = useState(false);

  const handleChange = (event, index) => {
    const newArray = [...guests];
    const { name, value } = event.target;
    newArray[index][name] = value;
    setGuests(newArray);
    validateSubmit();
  };

  const validateSubmit = () => {
    let isValid = true;
    guests.forEach((guest) => {
      isValid =
        (isValid &&
          !!guest.fullName.trim() &&
          !!guest.document.trim() &&
          guest.menu !== "otro") ||
        (isValid &&
          !!guest.fullName.trim() &&
          !!guest.document.trim() &&
          guest.menu === "otro" &&
          !!guest.otro.trim());
    });

    setIsButtonDisabled(!isValid);
  };

  const handleChangeGuestsNumber = (event) => {
    const currentLength = guests.length;
    const newLength = event.target.value;

    const delta = newLength - currentLength;
    let newArray = [...guests];

    if (delta > 0) {
      for (let index = 0; index < delta; index++) {
        newArray.push({
          fullName: "",
          document: "",
          menu: "normal",
          otro: "",
        });
      }
      setGuests(newArray);
      setGuestsNumber(event.target.value);
    } else {
      newArray = newArray.slice(0, newLength);
    }

    setGuests(newArray);
    setGuestsNumber(event.target.value);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const batch = writeBatch(db);
    try {
      guests.forEach((guest) => {
        const ref = doc(collection(db, "guests"));
        batch.set(ref, guest, guest.fullName);
        console.log(guest);
      });

      await batch.commit();
      console.log("Document written with ID: ");
      setIsLoading(false);
      setOpenSuccess(true);
      setGuests(emptyForm);
      setGuestsNumber(1);
    } catch (e) {
      setIsLoading(false);
      console.error("Error adding document: ", e);
      setOpenFailure(true);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSuccess(false);
    setOpenFailure(false);
  };

  return (
    <>
      <Snackbar
        open={openSuccess}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          sx={{ width: "100%", bgcolor: "success.light" }}
        >
          Invitados guardados con exito!
        </Alert>
      </Snackbar>
      <Snackbar
        open={openFailure}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="error"
          sx={{ width: "100%", bgcolor: "error.light" }}
        >
          Hubo un error al guardar los invitados! Por favor volver a intentar!
        </Alert>
      </Snackbar>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: {
            xs: "15px 15px 25px 10px",
            sm: "20px 23px 20px 20px",
            md: "30px 40px 30px 30px",
          },
        }}
      >
        <Typography
          variant="h1"
          fontFamily={"The Wedding Signature"}
          sx={{
            textAlign: { xs: "center", sm: "left" },
            fontSize: {
              xs: "46pt",
              mobileM: "55pt",
              mobileL: "65pt",
              mobileXl: "70pt",
              sm: "78pt",
              md: "120pt",
            },
          }}
        >
          Asistencia
        </Typography>
        <Box
          sx={{
            display: "flex",
            px: 1,
            pb: 1,
            flexDirection: { xs: "column", md: "row" },
            alignItems: { xs: "center", md: "flex-start" },
          }}
        >
          <Box display="flex" alignContent={"center"}>
            <Typography
              variant="h6"
              gutterBottom
              align="center"
              fontWeight={"bold"}
              sx={{
                fontSize: {
                  xs: "0.8rem",
                  mobileM: "0.8rem",
                  mobileL: "0.8rem",
                  mobileXl: "0.9rem",
                  sm: "1rem",
                  md: "1.25rem",
                },
              }}
            >
              ¿CUÁNTAS PERSONAS SON EN TU GRUPO FAMILIAR?
            </Typography>
          </Box>
          <FormControl
            sx={{ fullWidth: true, paddingTop: { xs: "4px", md: "0px" } }}
          >
            <TextField
              id="outlined-select-currency-native"
              select
              label="Cantidad"
              sx={{ minWidth: 147, ml: 1 }}
              value={guestsNumber}
              onChange={handleChangeGuestsNumber}
              SelectProps={{
                native: true,
              }}
              size="small"
            >
              {guestsNumbers.map((guestsNumber) => (
                <option key={guestsNumber.value} value={guestsNumber.value}>
                  {guestsNumber.label}
                </option>
              ))}
            </TextField>
          </FormControl>
        </Box>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            width: "100%",
            "& .MuiTextField-root": {
              m: 1,
              width: { xs: "94%", sm: "46%", md: "47%" },
            },
          }}
          noValidate
          autoComplete="off"
        >
          {guests.map((guest, index) => {
            return (
              <Box key={index} border="1px solid grey" mb={1}>
                <TextField
                  required
                  id="outlined-required"
                  name="fullName"
                  label="Nombre y Apellido"
                  value={guest.fullName}
                  onChange={(e) => handleChange(e, index)}
                />
                <TextField
                  required
                  id="outlined-required"
                  name="document"
                  label="Documento"
                  value={guest.document}
                  onChange={(e) => handleChange(e, index)}
                />
                <TextField
                  id="outlined-select-currency-native"
                  select
                  label="Menu"
                  name="menu"
                  value={guest.menu}
                  onChange={(e) => handleChange(e, index)}
                  SelectProps={{
                    native: true,
                  }}
                  helperText="Seleccioná una opción"
                >
                  {menues.map((menu) => (
                    <option key={menu.value} value={menu.value}>
                      {menu.label}
                    </option>
                  ))}
                </TextField>
                {guest.menu === "otro" && (
                  <TextField
                    required
                    id="outlined-required"
                    label="Otro"
                    name="otro"
                    value={guest.otro}
                    onChange={(e) => handleChange(e, index)}
                  />
                )}
              </Box>
            );
          })}
        </Box>
        <Box display="flex" justifyContent="end">
          <LoadingButton
            loading={isLoading}
            variant="outlined"
            onClick={handleSubmit}
            disabled={isButtonDisabled}
            size="large"
          >
            Guardar
          </LoadingButton>
        </Box>
      </Box>
    </>
  );
};
