import React, { useCallback, useEffect, useState } from "react";
import db from "../firebase";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { Box } from "@mui/system";
import {
  Card,
  CardContent,
  Container,
  Divider,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
/**
 * Component for showing the list of guests.
 *
 * @component
 * @example
 * const age = 21
 * const name = 'Jitendra Nirnejak'
 * return (
 *   <User age={age} name={name} />
 * )
 */
export const GuestListCard = (props) => {
  const [guests, setGuests] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const getGuestListAsync = useCallback(async () => {
    if (isLoading || guests.length === 0) {
      try {
        const ref = collection(db, "guests");
        const docs = await getDocs(query(ref, orderBy("fullName")));

        const newData = docs.docs.map((doc) => doc.data());
        setGuests(newData);
        setLoading(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    }
  }, [isLoading, guests.length]);

  useEffect(() => {
    getGuestListAsync();
  }, [getGuestListAsync]);

  return (
    <Container
      maxWidth="lg"
      sx={{
        pt: "24px",
        height: "auto",
      }}
    >
      <Card
        sx={{
          minWidth: 275,
          backgroundColor: "#f7f7f7",
          ...props,
        }}
      >
        {isLoading ? (
          <Skeleton variant="rounded" height={275} />
        ) : (
          <CardContent>
            <Typography variant="h5">Invitados!</Typography>
            <Divider></Divider>
            <Box display="flex" justifyContent="center">
              <TableContainer>
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }} align="left">
                        Nombre y Apellido
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }} align="right">
                        Documento
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }} align="right">
                        Menu
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }} align="right">
                        Otro
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {guests.map((row, i) => (
                      <TableRow
                        key={i}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="left">{row.fullName}</TableCell>
                        <TableCell align="right">{row.document}</TableCell>
                        <TableCell align="right">{row.menu}</TableCell>
                        <TableCell align="right">{row.otro}</TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell colSpan={2} sx={{ fontWeight: "bold" }}>
                        Total
                      </TableCell>
                      <TableCell align="right" sx={{ fontWeight: "bold" }}>
                        {guests.length}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </CardContent>
        )}
      </Card>
    </Container>
  );
};
