// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getFirestore } from "@firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDvyFVhaE75dn_eXReTFqNLj_SefvyJrgg",
  authDomain: "wedding-fc9f4.firebaseapp.com",
  projectId: "wedding-fc9f4",
  databaseURL: "https://wedding-fc9f4.firebaseio.com",
  storageBucket: "wedding-fc9f4.appspot.com",
  messagingSenderId: "348765134351",
  appId: "1:348765134351:web:1bc2f6d6f391e4bcbacd7a",
};

// Initialize Firebase
initializeApp(firebaseConfig);
export default getFirestore();
