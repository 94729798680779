import { useTheme } from "@emotion/react";
import { Snackbar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";

export const BankInformation = () => {
  const [open, setOpen] = useState(false);
  const handleClick = (value) => {
    setOpen(true);
    navigator.clipboard.writeText(value);
  };
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-end",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          [theme.breakpoints.up("xs")]: { marginBottom: "130px" },
          [theme.breakpoints.up("mobileM")]: { marginBottom: "155px" },
          [theme.breakpoints.up("mobileL")]: { marginBottom: "180px" },
          [theme.breakpoints.up("mobileXl")]: { marginBottom: "210px" },
          [theme.breakpoints.up("sm")]: { marginBottom: "240px!important" },
          [theme.breakpoints.up("md")]: { marginBottom: "380px!important" },

          cursor: "pointer",
        }}
        onClick={() => handleClick("BODA.ADRI.FLOR")}
      >
        <Typography
          ico
          variant="h6"
          align="center"
          fontWeight={"bold"}
          sx={{
            fontSize: {
              xs: "0.55rem",
              mobileM: "0.65rem",
              mobileL: "0.8rem",
              mobileXl: "0.9rem",
              sm: "1rem",
              md: "1.25rem",
            },
          }}
        >
          ALIAS CUENTA EN $: BODA.ADRI.FLOR
        </Typography>
        {/* <ContentCopyIcon fontSize="24px" sx={{ paddingLeft: "4px" }} /> */}
      </Box>
      <Snackbar
        message="COPIADO"
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={2000}
        onClose={() => setOpen(false)}
        open={open}
      />
    </Box>
  );
};
