import { useTheme } from "@emotion/react";
import { Box } from "@mui/material";
import { FormCard } from "./FormCard";

export const ImageCardForm = ({ image, onClick, children, ...props }) => {
  const theme = useTheme();
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Box
        onClick={onClick}
        sx={{
          backgroundImage: `url(${image})`,
          borderRadius: "4px",
          height: "100%",
          [theme.breakpoints.up("xs")]: { minHeight: "509px", width: "290px" },
          [theme.breakpoints.up("mobileM")]: {
            minHeight: "598px",
            width: "342px",
          },
          [theme.breakpoints.up("mobileL")]: {
            minHeight: "687px",
            width: "392px",
          },
          [theme.breakpoints.up("mobileXl")]: {
            minHeight: "820px",
            width: "468px",
          },
          [theme.breakpoints.up("sm")]: {
            minHeight: "935px !important",
            width: "534px !important",
          },
          [theme.breakpoints.up("md")]: {
            minHeight: "1444px !important",
            width: "825px !important",
          },

          maxWidth: "100%",
          alignContent: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          marginTop: "24px",

          ...props,
        }}
      >
        <FormCard />
      </Box>
    </Box>
  );
};
