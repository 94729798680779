import React from "react";
import { Container } from "@mui/system";
import { ImageCard } from "../components/ImageCard";
import { ImageCardForm } from "../components/ImageCardForm";
import { Box, Typography } from "@mui/material";
import { BankInformation } from "../components/BankInformation";

const page1 =
  "https://firebasestorage.googleapis.com/v0/b/wedding-fc9f4.appspot.com/o/Pag%201.png?alt=media&token=e6b95ecf-9755-4a62-aaa1-bd21524b691c";
const page2 =
  "https://firebasestorage.googleapis.com/v0/b/wedding-fc9f4.appspot.com/o/Pag%202.png?alt=media&token=7ff2fa60-24e7-4fb1-b8d1-b888b81f3fef";
const page3 =
  "https://firebasestorage.googleapis.com/v0/b/wedding-fc9f4.appspot.com/o/Pag%203.png?alt=media&token=80767a43-2fed-4159-9f4e-77fe88646e99";
const page4 =
  "https://firebasestorage.googleapis.com/v0/b/wedding-fc9f4.appspot.com/o/Pag%204.png?alt=media&token=621e6442-7eb3-4bfb-9a96-773a6861d7de";

export const Home = () => {
  return (
    <Container maxWidth="md">
      <ImageCard image={page1} />
      <ImageCard
        image={page2}
        onClick={() =>
          window.open("https://goo.gl/maps/47HdQ4J4Pp5mM7ue6", "_blank")
        }
        cursor="pointer"
      />
      <ImageCard image={page3}>
        <BankInformation></BankInformation>
      </ImageCard>
      <ImageCardForm image={page4} />

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Typography
          paddingY={"8px"}
          sx={{
            fontSize: {
              xs: "0.8rem",
              mobileM: "0.8rem",
              mobileL: "0.8rem",
              mobileXl: "0.9rem",
              sm: "1rem",
              md: "1.25rem",
            },
          }}
        >
          Página programada por Adri y diseñada por Flor.
        </Typography>
      </Box>
    </Container>
  );
};
