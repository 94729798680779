import { useTheme } from "@emotion/react";
import { Box } from "@mui/material";

export const ImageCard = ({ image, onClick, children, ...props }) => {
  const theme = useTheme();
  return (
    <Box
      onClick={onClick}
      sx={{
        backgroundImage: `url(${image})`,
        borderRadius: "4px",
        [theme.breakpoints.up("xs")]: { height: "509px" },
        [theme.breakpoints.up("mobileM")]: { height: "598px" },
        [theme.breakpoints.up("mobileL")]: { height: "687px" },
        [theme.breakpoints.up("mobileXl")]: { height: "820px" },
        [theme.breakpoints.up("sm")]: { height: "935px !important" },
        [theme.breakpoints.up("md")]: { height: "1444px !important" },
        // lg: "1935px",

        maxWidth: "100%",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        marginTop: "24px",
        ...props,
      }}
    >
      {children}
    </Box>
  );
};
